<template>
  <div v-loading="loading">
    <el-form
      label-position="right"
      ref="form"
      label-width="160px"
      :model="role"
      :rules="rules"
      class="demo-ruleForm"
    >
      <el-form-item label="角色名称：" prop="name">
        <el-col :span="8">
          <el-input v-model="role.name" maxlength="15" placeholder="请输入角色名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="角色类型：" prop="type">
        <el-col :span="8">
          <el-select v-model="role.type" placeholder="请选择角色类型">
            <el-option v-for="(item, ind) in TypeSelect" :key="ind" :label="item.name" :value="item.no"></el-option>
        </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="分配权限：">
        <el-tree
          :disabled="true"
          ref="t1"
          :data="data"
          show-checkbox
          :render-content="renderContent"
          node-key="fid"
          :props="defaultProps"
          class="lists"
          default-expand-all
          @node-expand="handleExpand"
          :default-checked-keys="menuIdList"
        ></el-tree>
      </el-form-item>
      <el-form-item class="center">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <span style="padding:0 20px"></span>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import RoleApi from "@/api/RoleApi";
import { navTo } from "@/utils/NavigatorUtils";
import { mapMutations } from "vuex";
export default {
  name: "SaveRolePage",
  props: {},
  components: {},
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
        type:[{ required: true, message: '请选择角色类型', trigger: 'change' }]
      },
      data: [],
      defaultProps: {
        children: "childrenList",
        label: "name",
        value: "id",
      },
      role: {
        name: "",
        type:"",
        menuIdList: [],
      },
      arr:[],
      menuIdList:[],
      loading:false,
      TypeSelect:[]
    };
  },
  async created() {
    this.handleExpand();
    await this.getDetail();
    await this.initData();
    await this.getFindRoleTypeSelect()
  },
  methods: {
      ...mapMutations({
      close: "mutationCloseTag",
    }),
    getFindRoleTypeSelect() {
      RoleApi.findRoleTypeSelect().then((res)=>{
        if(res.code == 200) {
          this.TypeSelect = res.data
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getDetail() {
      if(!this.$route.query.id) return
      this.loading = true;
      RoleApi.queryRoleById({ id: this.$route.query.id }).then((resp) => {
        if (resp.code == "200") {
          this.role = resp.data;
          this.getChildren(resp.data.menuList)
          let that = this
          setTimeout(function() {
            that.arr.forEach(value => {
            that.$refs.t1.setChecked(value, true, false);
            });
          }, 300);
          this.loading = false;
        } 
      });
    },
    getChildren(arr){
      arr.forEach(item => {
        console.log(item)
        if(item.childrenList != null && item.childrenList.length) {
          this.arr.push(item.fid)
          this.getChildren(item.childrenList)
        }else {
          this.arr.push(item.fid)
        }
      })
    },
    async initData() {
      let res = await RoleApi.roleAllMenulist({roleType:this.$route.query.type ? this.$route.query.type : ''});
      if (res.code === "200") {
        this.data = res.data;
      }else {
        this.$errorMsg(res.msg)
      }
    },
    cancel() {
       this.close({
         path:"/saveRole"
      });
      navTo(this, "/roleList");
    },
    handleExpand() {
      this.$nextTick(() => {
        this.changeCss();
      });
    },
    changeCss() {
      var levelName = document.getElementsByClassName("foo");
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.style.cssFloat = "left";
        levelName[i].parentNode.style.styleFloat = "left";
        levelName[i].parentNode.onmouseover = function () {
          this.style.backgroundColor = "#fff";
        };
      }
    },
    renderContent(h, { node, data, store }) {
      let classname = "";
      if (node.level === 3 && node.childNodes.length === 0) {
        classname = "foo";
      }
      if (node.level === 4 && node.childNodes.length === 0) {
        classname = "foo";
      }
      if (node.level === 5 && node.childNodes.length === 0) {
        classname = "foo";
      }
      return h(
        "p",
        {
          class: classname,
        },
        node.label
      );
    },
    submitForm() {
      let check = this.$refs.t1.getCheckedKeys();
      let Half = this.$refs.t1.getHalfCheckedKeys();
      this.role.menuIdList = check = check.concat(Half);
      this.$refs.form.validate((valid) => {
        if (valid) {
          //this.$confirmDialog("确定要进行当前操作吗?", () => {
            this.loading = true;
            this.role.id > 0
              ? this.updateRoleById(this.role)
              : this.addRole(this.role);
          //});
        } else {
          return false;
        }
      });
    },
    addRole(val) {
      RoleApi.saveRole(val).then((resp) => {
        if (resp.code == "200") {
          this.$successMsg(resp.msg);
          this.cancel();
        } else {
          this.$errorMsg(resp.msg);
        }
        this.loading = false;
      });
    },
    updateRoleById() {
      RoleApi.updateRole(this.role).then((resp) => {
        if (resp.code == "200") {
          this.user = {};
          this.$successMsg(resp.msg);
           this.cancel();
        } else {
          this.$errorMsg(resp.msg);
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.center{
  text-align: center;
  /* margin-top: 150px; */
}
</style>
